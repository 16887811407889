<template>
  <section class="path-user" style="padding-bottom: 100px">
    <div class="layout-container user-register-page">
      <main role="main" class="main-content-wrapper">
        <div class="login-wrapper">
          <AuthHeader />

          <transition name="scale">
            <div class="block-webform-block col-md-6 col-md-offset-3" v-if="!registrationSuccess">
              <h3 class="form-title">Register</h3>
              <form @submit.prevent="register()" ref="registerForm" class="user-register-form weblab-form" key="form">
                <h3 class="title">Login Credentials</h3>
                <!-- EMAIL -->
                <div class="form-item required">
                  <label>Email</label>
                  <input
                    :class="{ error: $v.email.$error || serverValidationErros.email }"
                    placeholder="eg. john.doe@mail.com"
                    autocorrect="none"
                    autocapitalize="none"
                    label="E-mail"
                    name="login"
                    v-model.trim="$v.email.$model"
                    type="email"
                    size="60"
                    maxlength="60"
                    class="form-text"
                  />
                  <div class="error description" v-if="!$v.email.required && $v.email.$dirty">Field is required</div>
                  <div class="error description" v-if="!$v.email.email && $v.email.$dirty">Field must have valid e-mail format</div>
                  <div class="error description" v-if="serverValidationErros.email">{{ serverValidationErros.email[0] }}</div>
                  <!-- <div class="description">Enter your e-mail that will be used as your username.</div> -->
                </div>

                <!-- PASSWORD -->
                <div class="form-item required">
                  <label>Password</label>
                  <input
                    :class="{ error: $v.password.$error }"
                    placeholder="Password"
                    label="Password"
                    name="password"
                    v-model.trim="$v.password.$model"
                    type="password"
                    size="60"
                    maxlength="128"
                    class="form-text required"
                  />
                  <div class="description error" v-if="!$v.password.required && $v.password.$dirty">Password is required</div>
                  <div class="description error" v-else-if="!$v.password.minLength">
                    Password must have at least {{ $v.password.$params.minLength.min }} characters.
                  </div>
                  <div class="description error" v-else-if="!$v.password.containsNumber && $v.password.$dirty">
                    Password must contain atleast 1 number
                  </div>
                  <div class="description error" v-else-if="!$v.password.containsSpecial && $v.password.$dirty">
                    Password must contain atleast one of the following special characters #?!@$%^&*-
                  </div>
                  <!-- <div class="description">Enter your password</div> -->
                </div>

                <!-- REPEAT PASSWORD -->
                <div class="form-item required">
                  <label>Repeat Password</label>
                  <input
                    :class="{ error: $v.password_confirmation.$error }"
                    placeholder="Repeat Password"
                    label="Password"
                    name="password"
                    v-model.trim="$v.password_confirmation.$model"
                    type="password"
                    size="60"
                    maxlength="128"
                    class="form-text required"
                  />
                  <div class="description error" v-if="!$v.password_confirmation.required && $v.password_confirmation.$dirty">
                    Password confirmation is required
                  </div>
                  <div class="description error" v-if="!$v.password_confirmation.sameAsPassword && $v.password_confirmation.$dirty">
                    Passwords must be identical.
                  </div>
                  <!-- <div class="description">Repeat your password</div> -->
                </div>

                <h3 class="title">Contact Person</h3>
                <!-- FIRST NAME -->
                <div class="form-item required">
                  <label>First Name</label>
                  <input
                    :class="{ error: $v.first_name.$error }"
                    placeholder="eg. John"
                    autocorrect="none"
                    label="First Name"
                    name="first_name"
                    v-model.trim="$v.first_name.$model"
                    type="text"
                    size="60"
                    maxlength="60"
                    class="form-text"
                  />
                  <div class="error description" v-if="!$v.first_name.required && $v.first_name.$dirty">Field is required</div>
                  <!-- <div class="description">Please enter the first name of the main contact person</div> -->
                </div>

                <!-- LAST NAME -->
                <div class="form-item required">
                  <label>Last Name</label>
                  <input
                    :class="{ error: $v.last_name.$error }"
                    placeholder="eg. Doe"
                    autocorrect="none"
                    label="Last Name"
                    name="last_name"
                    v-model.trim="$v.last_name.$model"
                    type="text"
                    size="60"
                    maxlength="60"
                    class="form-text"
                  />
                  <div class="error description" v-if="!$v.last_name.required && $v.last_name.$dirty">Field is required</div>
                  <!-- <div class="description">Please enter the last name of the main contact person</div> -->
                </div>

                <h3 class="title">Company Info</h3>
                <!-- COMPANY NAME -->
                <div class="form-item required">
                  <label>Company Name</label>
                  <input
                    :class="{ error: $v.company_name.$error }"
                    placeholder="eg. Acme N.V."
                    autocorrect="none"
                    label="Company Name"
                    name="company_name"
                    v-model.trim="$v.company_name.$model"
                    type="text"
                    size="60"
                    maxlength="60"
                    class="form-text"
                  />
                  <div class="error description" v-if="!$v.company_name.required && $v.company_name.$dirty">Field is required</div>
                  <!-- <div class="description">Please enter the company name</div> -->
                </div>

                <!-- COMPANY ADDRESS -->
                <div class="form-item required">
                  <label>Company Address</label>
                  <input
                    :class="{ error: $v.company_address.$error }"
                    placeholder="eg. Oranjestad 200A"
                    autocorrect="none"
                    label="Company Address"
                    name="company_address"
                    v-model.trim="$v.company_address.$model"
                    type="text"
                    size="60"
                    maxlength="60"
                    class="form-text"
                  />
                  <div class="error description" v-if="!$v.company_address.required && $v.company_address.$dirty">Field is required</div>
                  <!-- <div class="description">Please enter the company address, must be located on Aruba</div> -->
                </div>
                <div class="address-country-field">Aruba</div>

                <!-- PHONE NUMBER -->
                <div class="form-item required">
                  <label>Phone Number</label>
                  <div class="phone-input-wrapper">
                    <div class="phone-prefix-field">+297</div>
                    <input
                      :class="{ error: $v.phone_number.$error }"
                      placeholder="eg. 5931234"
                      autocorrect="none"
                      label="Phone Number"
                      name="phone_number"
                      v-model.trim="$v.phone_number.$model"
                      type="tel"
                      size="60"
                      maxlength="60"
                      class="form-text"
                    />
                  </div>
                  <div class="error description" v-if="!$v.phone_number.required && $v.phone_number.$dirty">Field is required</div>
                  <div class="error description" v-if="!$v.phone_number.numeric">Field must be numeric with no spaces</div>
                  <!-- <div class="description">Please enter the phone number you wished to be contacted at</div> -->
                </div>

                <!-- RECAPCHA -->
                <vue-recaptcha
                  sitekey="6LeOxSUUAAAAAHCDmgXGVlAkk3xoQnAQ9vGAQd1Y"
                  :loadRecaptchaScript="true"
                  @verify="onVerify"
                  @expired="notRobot = false"
                  @error="notRobot = false"
                  style="margin-top: 20px"
                >
                </vue-recaptcha>

                <!-- register btn -->
                <div class="form-actions" style="margin-top: 30px">
                  <input type="submit" name="op" value="Register" class="button js-form-submit form-submit" :disabled="notRobot ? false : true" />
                  <span class="glyphicon glyphicon-arrow-right"></span>
                </div>
              </form>
              <p
                class="text-danger"
                v-if="$v.$anyError || Object.keys(serverValidationErros).length !== 0"
                style="font-weight: 600; font-size: 14px; float: right"
              >
                Please fix any validation errors and try again.
              </p>
            </div>
            <div v-else class="alert alert-info alert-custom" key="success">
              <div class="checkmark-wrapper info">
                <!-- <i class="glyphicon glyphicon-ok"></i> -->
                <i class="glyphicon glyphicon-exclamation-sign"></i>
              </div>
              <h3 class="info">Registration Pending Verification</h3>
              <p>
                An e-mail has been sent to <strong>{{ email }}</strong> to verify your registration. Once you have verified your registration you will
                be able to login to the {{ $store.state.config.siteName }}.
              </p>
              <p class="text-danger" style="font-weight: 600; font-size: 14px">
                Please take note that your verification e-mail will expire in 60 minutes.
              </p>
            </div>
          </transition>

          <router-link to="login" class="btn-register">Go to Login</router-link>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs, email, numeric } from 'vuelidate/lib/validators';
import AuthHeader from '@/components/generic/AuthHeader.vue';
import VueRecaptcha from 'vue-recaptcha';

export default {
  mixins: [validationMixin],
  components: {
    AuthHeader,
    VueRecaptcha,
  },
  data() {
    return {
      email: '',
      password: '',
      password_confirmation: '',
      first_name: '',
      last_name: '',
      company_name: '',
      company_address: '',
      phone_number: '',
      serverValidationErros: {},
      registrationSuccess: false,
      notRobot: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
      containsNumber: function (value) {
        return /[0-9]/.test(value);
      },
      containsSpecial: function (value) {
        return /[#?!@$%^&*-]/.test(value);
      },
    },
    password_confirmation: {
      required,
      sameAsPassword: sameAs('password'),
    },
    first_name: {
      required,
    },
    last_name: {
      required,
    },
    company_name: {
      required,
    },
    company_address: {
      required,
    },
    phone_number: {
      required,
      numeric,
    },
  },
  created() {
    if (localStorage.getItem('my_lab_logged_in') == 'true') {
      this.$router.push({ name: 'Home' }).then((_) => location.reload());
    }
  },
  methods: {
    register() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit('setTransLoader', true);
        this.$store
          .dispatch('REGISTER', {
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            first_name: this.first_name,
            last_name: this.last_name,
            company_name: this.company_name,
            company_address: this.company_address,
            phone_number: this.phone_number,
          })
          .then((res) => {
            //if there are server validation errors
            this.serverValidationErros = {};
            if (res?.response?.data?.errors) {
              this.serverValidationErros = res.response.data.errors;
            } else if (res.result === true) {
              this.registrationSuccess = true;
              jQuery('html,body').animate({ scrollTop: 0 }, 100);
            } else {
              this.showRegistrationError();
            }
          })
          .catch((e) => {
            console.log(e);
            this.showRegistrationError();
          })
          .finally(() => this.$store.commit('setTransLoader', false));
      }
    },
    showRegistrationError() {
      this.$snotify.error(
        `Error during registraion. Please try again. If problems persists please call ${this.$store.state.config.supportTel} for further assistance.`,
        '',
        {
          timeout: 0,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          position: 'centerTop',
        }
      );
    },
    onVerify: function (response) {
      if (response) this.notRobot = true;
    },
  },
};
</script>

<style lang="scss">
.user-register-page {
  .btn-register {
    margin-top: 40px;
    margin-left: 10px;
    line-height: 1.5rem;
    text-align: center;
    box-shadow: none;
    font-size: 1.2rem;
  }
  .user-register-form {
    //padding: 30px 10px 20px;
    .form-item {
      margin: 10px 0;
      label {
        margin-bottom: 5px;
      }
    }
    .form-item.required:before {
      position: absolute;
      top: 32px;
      right: 0;
      content: '';
      vertical-align: super;
      background-image: url(../../../assets/img/generic/required.svg);
      background-repeat: no-repeat;
      background-size: 6px 6px;
      width: 6px;
      height: 6px;
      margin: 0 0.3em;
    }
    .form-actions {
      display: flex;
      margin-top: 20px;
      margin-bottom: 10px;
      justify-content: flex-end;
      position: relative;
      .form-submit {
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .user-register-page .block-webform-block {
    padding: 20px;
  }
}
</style>